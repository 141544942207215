<template>
    <v-card class="StatisticsCard">
        <v-card-title>
            <v-row class="ma-0 pa-0" style="height: calc(0.1*0.7*(100vh - 64px)); width: 100%;">
                <v-col class="ma-0 pa-0 statsTableTitle">
                    User Sessions
                </v-col>
                <v-col class="ma-0 pa-0 text-right" style="height: 100%;">
                    <v-chip class="ma-2 statsTableChip chip-1" :outlined="viewing_session_type != 'Today'" :disabled="loading_new_sessions" @click="viewing_session_type='Today'">
                        <v-icon class="text-left mr-1">mdi-calendar</v-icon>
                        Today
                    </v-chip>
                    <v-chip class="ma-2 statsTableChip chip-2" :outlined="viewing_session_type != 'This Week'" :disabled="loading_new_sessions" @click="viewing_session_type='This Week'">
                        <v-icon class="text-left mr-1">mdi-calendar-range</v-icon>
                        This Week
                    </v-chip>
                    <v-chip class="ma-2 statsTableChip chip-3" :outlined="viewing_session_type != 'This Month'" :disabled="loading_new_sessions" @click="viewing_session_type='This Month'">
                        <v-icon class="text-left mr-1">mdi-calendar-month</v-icon>
                        This Month
                    </v-chip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" @click="load_new_sessions(viewing_session_type)">mdi-refresh</v-icon>
                        </template>
                        <span>Last Refresh: {{last_refresh}}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-card-title>
        <v-row style="width: 100%; height: calc((0.9*0.7*(100vh - 64px)) - 61px);" align="center" justify="center" v-if="loading_new_sessions">
            <v-col cols="auto">
                <v-progress-circular indeterminate color="secondary" size="75"></v-progress-circular>
            </v-col>
        </v-row>
        <v-row style="width: 100%; height: calc((0.9*0.7*(100vh - 64px)) - 61px);" align="center" justify="center" v-if="sessions.length == 0 && !loading_new_sessions">
            <v-col cols="auto">
                <span class="statsTableHeader">No Available Sessions</span>
            </v-col>
        </v-row>
        <v-data-table :headers="sessions_header" :items="sessions" :items-per-page="5" fixed-header height="calc((0.9*0.7*(100vh - 64px)) - 169px)" v-if="sessions.length != 0 && !loading_new_sessions">
            <template v-slot:body="{items}">
                <tbody>
                    <tr v-for="row in items" :key="row.id">
                        <td class="text-left statsTableData">{{row['user']}}</td>
                        <td class="text-left statsTableData">{{row['runs']}}</td>
                        <td class="text-left statsTableData">{{row['pdfs'] + row['pptxs']}}</td>
                        <td class="text-left statsTableData">{{formatDate(new Date(row['session_start']))}}</td>
                        <td class="text-left statsTableData">{{timeDiffCalc(new Date(row['session_end']),new Date(row['session_start']))}}</td>
                        <td class="text-left statsTableData"><v-icon :color="determineActive(new Date(row['session_end'])) ? '#0A8C4E' : '#B41D1D'">mdi-checkbox-blank-circle</v-icon></td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer>
                <v-row class="ma-3 pa-0">
                    <v-col class="py-0">
                        <span class="statsTableHighlight">Total Users: {{total_users}}</span>
                    </v-col>
                    <v-col class="py-0">
                        <span class="statsTableHighlight">Total Runs: {{total_runs}}</span>
                    </v-col>
                    <v-col class="py-0">
                        <span class="statsTableHighlight">Total Exports: {{total_exports}}</span>
                    </v-col>
                    <v-col class="py-0">
                        <span class="statsTableHighlight">Total Time: {{total_session_time}}</span>
                    </v-col>
                </v-row>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import Stats from '../javascript/statistics_caller'
    export default {
        name: "Sessions",
        props: [
          'download'
        ],
        data(){
            return{
                viewing_session_type: 'Today',
                sessions_header: [
                    {text: 'Username', align: 'start', value: 'user'},
                    {text: 'Runs', align: 'start', value: 'runs'},
                    {text: 'Exports', align: 'start', value: 'Exports'},
                    {text: 'Session Start', align: 'start', value: 'session_start'},
                    {text: 'Session Time', align: 'start', value: 'session_time'},
                    {text: 'Status', align: 'start', value: 'status'},
                ],
                total_users: 0,
                total_runs: 0,
                total_exports: 0,
                total_session_time: 0,
                users: [],
                sessions: [],
                last_refresh: 0,
                loading_new_sessions: true
            }
        },
        watch:{
            viewing_session_type: {
                immediate: true,
                handler: function(){
                    this.load_new_sessions(this.viewing_session_type);
                }
            },
            download: {
                immediate: true,
                handler: function () {
                    if(this.download == true) {
                        let element = {'settings': {'type': this.viewing_session_type}, 'data': this.sessions, 'totals': {'users': this.total_users, 'runs': this.total_runs, 'exports': this.total_exports, 'time': this.total_session_time}};
                        this.$emit('downloadElement', {'sessions': element})
                    }
                }
            }
        },
        mounted(){
            this.load_new_sessions('Today');
        },
        methods: {
            load_new_sessions: async function(session_type){
                this.loading_new_sessions = true;
                let token = this.$token.get_val();
                if(session_type == 'Today'){
                    let ses = await Stats.todays_sessions(token).then((resp) => {
                        return resp
                    }).catch((err) => {
                        console.error(err)
                        return []
                    });
                    // ses = JSON.parse(ses);
                    ses.sort(function(a,b){
                        return new Date(b['session_end']) - new Date(a['session_end']);
                    });
                    this.sessions = ses;
                }
                else if(session_type == 'This Week'){
                    let ses = await Stats.week_sessions(token).then((resp) => {
                        return resp
                    }).catch((err) => {
                        console.error(err)
                        return []
                    });
                    // ses = JSON.parse(ses);
                    ses.sort(function(a,b){
                        return new Date(a['session_end']) - new Date(b['session_end']);
                    });
                    this.sessions = ses;
                }
                else if(session_type == 'This Month'){
                    let ses = await Stats.month_sessions(token).then((resp) => {
                        return resp
                    }).catch((err) => {
                        console.error(err)
                        return []
                    });
                    // ses = JSON.parse(ses);
                    ses.sort(function(a,b){
                        return new Date(a['session_end']) - new Date(b['session_end']);
                    });
                    this.sessions = ses;
                }
                else{
                    this.sessions = [];
                }
                this.last_refresh = new Date().toLocaleString();
                this.calculate_totals();
                this.loading_new_sessions = false;
            },
            formatDate: function(date){
                date.setTime(date.getTime() + 3600 * 1000);
                let str = date.toUTCString().substr(5,17);
                return str;
            },
            timeDiffCalc: function(dateFuture, dateNow) {
                let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

                // calculate days
                const days = Math.floor(diffInMilliSeconds / 86400);
                diffInMilliSeconds -= days * 86400;

                // calculate hours
                const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
                diffInMilliSeconds -= hours * 3600;

                // calculate minutes
                const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
                diffInMilliSeconds -= minutes * 60;

                let difference = '';
                if (days > 0) {
                    difference += (days === 1) ? `${days} day, ` : `${days} days, `;
                }
                if (hours > 0) {
                    difference += (hours === 0 || hours === 1) ? `${hours} hour, ` : `${hours} hours, `;
                }

                difference += (minutes === 0 || hours === 1) ? `${minutes} minutes` : `${minutes} minutes`;

                return difference;
            },
            determineActive(date_end){
                date_end.setTime(date_end.getTime() + (5 * 3600 * 1000));
                let today = new Date();
                let diffInMilliSeconds = Math.abs(date_end - today) / 1000;
                const days = Math.floor(diffInMilliSeconds / 86400);
                diffInMilliSeconds -= days * 86400;

                // calculate hours
                const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
                diffInMilliSeconds -= hours * 3600;

                // calculate minutes
                const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
                diffInMilliSeconds -= minutes * 60;
                if(days > 0 || hours > 0){
                    return false;
                }
                else{
                    return true;
                }
            },
            calculate_totals(){
                let total_runs = 0;
                let total_exports = 0;
                let diffInMilliSeconds = 0;
                let unique_users = [];
                for(let ses of this.sessions){
                    if(!unique_users.includes(ses['user'])){
                        unique_users.push(ses['user'])
                    }
                    total_runs += ses['runs'];
                    total_exports += ses['pdfs'] + ses['pptxs'];
                    diffInMilliSeconds += Math.abs(new Date(ses['session_end']) - new Date(ses['session_start'])) / 1000
                }
                const days = Math.floor(diffInMilliSeconds / 86400);
                diffInMilliSeconds -= days * 86400;

                // calculate hours
                const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
                diffInMilliSeconds -= hours * 3600;

                // calculate minutes
                const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
                diffInMilliSeconds -= minutes * 60;
                let time_str = '';
                if(days > 1){
                    time_str += days + " days, ";
                }
                if(hours > 1){
                    time_str += hours + " hours, ";
                }
                time_str += minutes + " minutes";
                this.total_users = unique_users.length;
                this.total_runs = total_runs;
                this.total_exports = total_exports;
                this.total_session_time = time_str;
            }
        }
    }
</script>

<style scoped>

</style>
import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

Vue.use(Router)
const router = new Router({
  routes: [
    {
        path: '*',
        redirect: '/login'
    },
    {
        path: '/',
        redirect: '/login'
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
          requiresAuth: true,
          title: 'Docma'
      }
    },
    {
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Docma'
        }
    }
  ]
});
router.beforeEach((to,from,next) => {
    const currentUser = Vue.prototype.$token.get_val();
    const requiresAuth = to.matched.some(record=>record.meta.requiresAuth);

    document.title = to.meta.title;

    if(requiresAuth && !currentUser){
        next('Login');
    }
    else{
        next();
    }
});

export default router

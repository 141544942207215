import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi'
    },
    theme:{
        themes: {
            light: {
                primary: '#EE8500',
                secondary: '#f3a815',
                third: '#000000'
                // primary: '#00285e',
                // secondary: '#0079C1',
                // third: '#00285e'
            },
        },
    },
});

<template>
  <v-card class="StatisticsCard">
    <v-row class="ma-0 pa-0" style="width: 100%; height: 100%;" v-if="!loading_new_data">
      <v-col class="ma-0 pa-0" cols="auto" style="width: 90%; height: 100%;">
        <GChart
          style="width: 90%; height: 100%; position: absolute; z-index: 2;"
          type="LineChart"
          :data="chartData"
          :options="chartOptions"
          ref="statsChart"
        />
      </v-col>
      <v-col class="ma-0 pa-0" cols="auto" style="width: 10%; height: 100%;">
        <v-row class="ma-0 pa-0" style="width: 100%;">
          <v-col cols="12" class="text-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="get_past_week_data()">mdi-refresh</v-icon>
              </template>
              <p>Last Refresh: {{last_refresh}}</p>
              <p>Last System Refresh: {{last_sys_refresh}}</p>
            </v-tooltip>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-menu v-model="date_selector" offset-x left :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="third" v-bind="attrs" v-on="on">mdi-calendar-range</v-icon>
              </template>
              <v-card class="pa-3">
                <v-menu
                        ref="startDatePicker"
                        v-model="startDatePicker"
                        :close-on-content-click="false"
                        :return-value.sync="selectedStartDate"
                        transition="scale-transition"
                        offset-y
                        min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                            v-model="selectedStartDate[0]"
                            :label="month_view ? 'Month' : 'Week'"
                            prepend-icon="mdi-calendar-search"
                            color="primary"
                            readonly
                            v-bind="attrs"
                            class="selectors"
                            v-on="on"></v-text-field>
                  </template>
                  <v-date-picker class='selectorCalendar' v-model="selectedStartDate" no-title scrollable :min="minStartDate" :max="maxEndDate" multiple @input="update_date_range($event)">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startDatePicker = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.startDatePicker.save(selectedStartDate); startDatePicker = false; date_selector = false; get_past_week_data()">OK</v-btn>
                  </v-date-picker>
                </v-menu>
                <v-switch v-model="month_view" label="View By Month" @change="update_date_range(selectedStartDate); get_past_week_data()">Month View</v-switch>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-menu v-model="group_selector" offset-x left :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="third" v-bind="attrs" v-on="on">mdi-account-group-outline</v-icon>
              </template>
              <v-card class="pa-3">
                <v-select class="selectors" multiple v-model="selected_groups" :items="groups"></v-select>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <v-switch prepend-icon="mdi-play-outline" color="third" v-model="view_runs" class="ma-0 pa-0"></v-switch>
          </v-col>
          <v-col cols="12">
            <v-switch prepend-icon="mdi-account-multiple" color="third" v-model="view_sessions" class="ma-0 pa-0"></v-switch>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" style="width: 100%; height: 100%;" justify="center" align="center" v-if="loading_new_data">
        <v-col cols="auto">
          <v-progress-circular indeterminate color="secondary" size="75"></v-progress-circular>
        </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import Stats from '../javascript/statistics_caller'
  export default {
    name: 'RunsSessionsGraph',
    props: [
        'download'
    ],
    data() {
        return {
            chartDataHeader: [
                {"id": "", "label": "Day", "pattern": "", "type": "string", "p": {"role": "domain"}},
                {"id": "", "label": "Runs", "pattern": "", "type": "number", "p": {"role": "data"}},
                {"id": "", "label": "Sessions", "pattern": "", "type": "number", "p": {"role": "data"}},
                {"id": "", "label": "Avg Runs", "pattern": "", "type": "number", "p": {"role": "data"}},
                {"id": "", "label": "Avg Sessions", "pattern": "", "type": "number", "p": {"role": "data"}}
            ],
            chartStarterData: [
                {"c": [{"v": 'Monday', "f": null},{"v": 0, "f": null},{"v": 0, "f": null},{"v": 0, "f": null},{"v": 0, 'f': null}]},
                {"c": [{"v": 'Tuesday', "f": null},{"v": 0, "f": null},{"v": 0, "f": null},{"v": 0, "f": null},{"v": 0, 'f': null}]},
                {'c': [{'v': 'Wednesday', 'f': null},{'v': 0, 'f': null},{'v': 0, 'f': null},{'v': 0, 'f': null},{'v': 0, 'f': null}]},
                {'c': [{'v': 'Thursday', 'f': null},{'v': 0, 'f': null},{'v': 0, 'f': null},{'v': 0, 'f': null},{'v': 0, 'f': null}]},
                {'c': [{'v': 'Friday', 'f': null},{'v': 0, 'f': null},{'v': 0, 'f': null},{'v': 0, 'f': null},{'v': 0, 'f': null}]},
                {'c': [{'v': 'Saturday', 'f': null},{'v': 0, 'f': null},{'v': 0, 'f': null},{'v': 0, 'f': null},{'v': 0, 'f': null}]},
                {'c': [{'v': 'Sunday', 'f': null},{'v': 0, 'f': null},{'v': 0, 'f': null},{'v': 0, 'f': null},{'v': 0, 'f': null}]}
            ],
            chartData: {"cols": null, "rows": [], "p": null},
            chartOptions: {
                title: 'Weekly Sessions & Runs',
                titleTextStyle: {fontName: 'FuturaTLight', fontSize: 18, bold: false},
                colors: ['#0079c1', '#AD343E', '#F2AF29', '#FF3CC7', '#00E5E8'],
                vAxes: {
                    0: {
                        title: '',
                        titleTextStyle: {fontName: 'FuturaTMedium', fontSize: 14, italic: false},
                        textStyle: {fontName: 'FuturaTMedium', fontSize: 10}
                    }
                },
                vAxis: {
                    minValue: 0,
                    maxValue: 10,
                    viewWindowMode: 'explicit',
                    viewWindow: {min: 0, max: 100},
                    baselineColor: '#000000',
                    gridlines: {count: 0},
                    minorGridlines: {count: 0}
                },
                hAxis: {
                    viewWindowMode: 'explicit',
                    format: 'String',
                    slantedText: true,
                    slantedTextAngle: 15,
                    baselineColor: '#000000',
                    textStyle: {fontName: 'FuturaTMedium', fontSize: 10},
                    gridlines: {count: 0},
                    minorGridlines: {count: 0}
                },
                curveType: 'function',
                legend: {position: 'top'},
                chartArea: {left: '5%', top: '10%', width: '90%', height: '80%'},
                pointSize: 5,
                backgroundColor: 'none',
                series: {
                    0: {
                        color: '#ff5000'
                    },
                    1: {
                        color: '#5000FF'
                    },
                    2: {
                        color: '#ff5000',
                        lineDashStyle: [4, 8]
                    },
                    3: {
                        color: '#5000FF',
                        lineDashStyle: [4, 8]
                    },
                    4: {
                        color: '#fac218'
                    },
                    5: {
                        color: '#111d4a'
                    },
                    6: {
                        color: '#fac218',
                        lineDashStyle: [4, 8]
                    },
                    7: {
                        color: '#111d4a',
                        lineDashStyle: [4, 8]
                    },
                    8: {
                        color: '#f42272'
                    },
                    9: {
                        color: '#3a5a40'
                    },
                    10: {
                        color: '#f42272',
                        lineDashStyle: [4, 8]
                    },
                    11: {
                        color: '#3a5a40',
                        lineDashStyle: [4, 8]
                    }
                }
            },
            raw_data: {},
            loading_new_data: true,
            date_selector: false,
            startDatePicker: false,
            selectedStartDate: null,
            minStartDate: null,
            maxEndDate: null,
            group_selector: false,
            groups: [],
            selected_groups: [],
            view_runs: true,
            view_sessions: true,
            month_view: false,
            last_refresh: 0,
            last_sys_refresh: 0
        }
    },
    mounted(){
      this.chartData = {"cols": this.chartDataHeader, "rows": this.chartStarterData, "p": null};
      this.get_past_min_max_dates();
      this.get_this_week_data();
    },
    watch: {
        selected_groups: {
            immediate: true,
            handler(){
                this.update_data(this.raw_data);
            },
            deep: true
        },
        view_runs: {
            immediate: true,
            handler(){
                this.update_data(this.raw_data);
            }
        },
        view_sessions: {
            immediate: true,
            handler(){
                this.update_data(this.raw_data);
            }
        },
        download: {
            immediate: true,
            handler: function () {
                if(this.download == true) {
                    let chart = this.$refs.statsChart.chartObject;
                    let chartImage = chart.getImageURI();
                    this.$emit('downloadElement', {'overview': {'data': chartImage, 'settings': {'type': this.month_view ? 'Monthly' : 'Weekly'}}})
                }
            }
        }
    },
    methods :{
          update_data: function(data){
              this.loading_new_data = true;
              let groups = this.selected_groups;
              let group = [];
              if(groups.length == 0){
                  groups = Object.keys(data);
                  if(groups.includes('population')){
                      this.selected_groups.push('population');
                      group.push('population');
                  }
                  else if(groups.length > 0){
                      this.selected_groups.push(groups[0]);
                      group.push(groups[0])
                  }
              }
              else{
                  group = groups;
              }
              let days = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];
              let max = 0;
              let updated_data = [];
              let chartHeader = [{"id": "", "label": "Day", "pattern": "", "type": "string", "p": {"role": "domain"}}];
              let colors = ['#ff5000','#5000FF','#fac218','#111d4a','#f42272','#3a5a40'];
              let s_n = 0;
              let c_n = 0;
              let series = {};
              for(let g of group) {
                  if (this.view_runs) {
                      chartHeader.push({
                          "id": "",
                          "label": g + " Runs",
                          "pattern": "",
                          "type": "number",
                          "p": {"role": "data"}
                      });
                      series[s_n] = {
                          color: colors[c_n]
                      };
                      s_n += 1;
                  }
                  if (this.view_sessions) {
                      chartHeader.push({
                          "id": "",
                          "label": g + " Sessions",
                          "pattern": "",
                          "type": "number",
                          "p": {"role": "data"}
                      });
                      series[s_n] = {
                          color: colors[c_n + 1]
                      };
                      s_n += 1;
                  }
                  if (this.view_runs) {
                      chartHeader.push({
                          "id": "",
                          "label": g + " Average Runs",
                          "pattern": "",
                          "type": "number",
                          "p": {"role": "data"}
                      });
                      series[s_n] = {
                          color: colors[c_n],
                          lineDashStyle: [4, 8]
                      };
                      s_n += 1;
                  }
                  if (this.view_sessions) {
                      chartHeader.push({
                          "id": "",
                          "label": g + " Average Sessions",
                          "pattern": "",
                          "type": "number",
                          "p": {"role": "data"}
                      });
                      series[s_n] = {
                          color: colors[c_n + 1],
                          lineDashStyle: [4, 8]
                      };
                      s_n += 1;
                  }
                  c_n += 2;
              }
              if(data == null || data == {}){
                updated_data = this.chartStarterData;
                chartHeader = this.chartDataHeader;
              }
              else{
                  let row = [];
                  let limit = 7;
                  if(this.month_view){
                      limit = this.selectedStartDate.length;
                  }
                  for (let i = 0; i < limit; i++) {
                      if(this.month_view){
                          row = [{"v":this.selectedStartDate[i], "f": null}];
                      }
                      else {
                          row = [{"v": days[i], "f": null}];
                      }
                      for(let g of group) {
                          if (data[g]['runs'][i] > max && this.view_runs) {
                              max = data[g]['runs'][i];
                          }
                          if (data[g]['average_runs'][i] > max && this.view_runs) {
                              max = data[g]['average_runs'][i];
                          }
                          if (data[g]['sessions'][i] > max && this.view_sessions) {
                              max = data[g]['sessions'][i];
                          }
                          if (data[g]['average_sessions'][i] > max && this.view_sessions) {
                              max = data[g]['average_sessions'][i];
                          }
                          if(this.view_runs) {
                              row.push({"v": data[g]['runs'][i], "f": null});
                          }
                          if(this.view_sessions) {
                              row.push({"v": data[g]['sessions'][i], "f": null});
                          }
                          if(this.view_runs) {
                              row.push({"v": data[g]['average_runs'][i], "f": null});
                          }
                          if(this.view_sessions) {
                              row.push({"v": data[g]['average_sessions'][i], "f": null});
                          }
                      }
                      updated_data.push({"c": row})
                  }
              }
              max = max * 1.1;
              this.chartOptions.vAxis.viewWindow.max = max;
              this.chartOptions.series = series;
              this.chartData = {"cols": chartHeader, "rows": updated_data, "p": null};
              this.last_refresh = new Date().toLocaleString();
              let d = new Date();
              d.setHours(d.getHours(),0,0,0);
              this.last_sys_refresh = d.toLocaleString();
              this.loading_new_data = false;
          },
          get_this_week_data: async function(){
              this.loading_new_data = true;
              let token = this.$token.get_val();
              let runs = {};
              let sessions = {};
              let average_runs = {};
              let average_sessions = {};
              if(!this.month_view) {
                  runs = await Stats.get_week_at_a_glance_runs(token);
                  sessions = await Stats.get_week_at_a_glance_sessions(token);
                  average_runs = await Stats.get_week_at_a_glance_average_runs(token);
                  average_sessions = await Stats.get_week_at_a_glance_average_sessions(token);
              }
              else{
                  runs = await Stats.get_month_at_a_glance_runs(token);
                  sessions = await Stats.get_month_at_a_glance_sessions(token);
                  average_runs = await Stats.get_month_at_a_glance_average_runs(token);
                  average_sessions = await Stats.get_month_at_a_glance_average_sessions(token);
              }
              // runs = JSON.parse(runs);
              // sessions = JSON.parse(sessions);
              // average_runs = JSON.parse(average_runs);
              // average_sessions = JSON.parse(average_sessions);
              let groups = Object.keys(runs);
              this.groups = groups;
              let response = {};
              for(let group of groups){
                  response[group] = {
                      'runs': runs[group]['runs'],
                      'sessions': sessions[group]['sessions'],
                      'average_runs': average_runs[group]['runs'],
                      'average_sessions':  average_sessions[group]['sessions']
                  }
              }
              this.raw_data = response;
              this.update_data(response);
              this.loading_new_data = false;
          },
          get_past_min_max_dates: async function(){
              let token = this.$token.get_val();
              let past_date_range = await Stats.get_past_min_max(token);
              // past_date_range = JSON.parse(past_date_range);
              let start = new Date(past_date_range[0]);
              let end = new Date(past_date_range[1]);
              end.setDate(end.getDate() + 6);
              let selectedStart = new Date(past_date_range[1]);
              selectedStart.setDate(selectedStart.getDate());
              this.minStartDate = start.toISOString().substr(0,10);
              this.maxEndDate = end.toISOString().substr(0,10);
              this.update_date_range([0,0,0,0,0,0,0,this.maxEndDate]);
          },
          get_past_week_data: async function(){
              this.loading_new_data = true;
              let startDate = this.selectedStartDate[0];
              let todayDate = new Date();
              console.log(todayDate.getDay())
              const fdw = new Date(todayDate.setDate(todayDate.getDate() - todayDate.getDay()));
              fdw.setHours(0,0,0);
              const ldw = new Date(fdw);
              ldw.setDate(ldw.getDate() + 6);
              ldw.setHours(0,0,0);
              console.log(fdw);
              console.log(ldw);
              let this_week = new Date(startDate) >= fdw && new Date(startDate) <= ldw;
              console.log(this_week)
              if(this_week){
                  this.get_this_week_data();
              }
              else {
                  let token = this.$token.get_val();
                  let past_week_runs = {};
                  let past_week_sessions = {};
                  let past_week_average_runs = {};
                  let past_week_average_sessions = {};
                  if(!this.month_view){
                      past_week_runs = await Stats.get_past_week_at_a_glance_runs(token, startDate);
                      past_week_sessions = await Stats.get_past_week_at_a_glance_sessions(token, startDate);
                      past_week_average_runs = await Stats.get_past_week_at_a_glance_average_runs(token, startDate);
                      past_week_average_sessions = await Stats.get_past_week_at_a_glance_average_sessions(token, startDate);
                  }
                  else{
                      past_week_runs = await Stats.get_past_month_at_a_glance_runs(token, startDate);
                      past_week_sessions = await Stats.get_past_month_at_a_glance_sessions(token, startDate);
                      past_week_average_runs = await Stats.get_past_month_at_a_glance_average_runs(token, startDate);
                      past_week_average_sessions = await Stats.get_past_month_at_a_glance_average_sessions(token, startDate);
                  }
                  let groups = Object.keys(past_week_runs);
                  this.groups = groups;
                  let response = {};
                  for(let group of groups){
                      response[group] = {
                          'runs': past_week_runs[group]['runs'],
                          'sessions': past_week_sessions[group]['sessions'],
                          'average_runs': past_week_average_runs[group]['runs'],
                          'average_sessions':  past_week_average_sessions[group]['sessions']
                      }
                  }
                  this.raw_data = response;
                  this.update_data(response);
              }
              this.loading_new_data = false;
          },
          update_date_range: function(dateRange){
              if(this.month_view){
                  this.update_date_range_month(dateRange);
              }
              else{
                  this.update_date_range_week(dateRange);
              }
          },
          update_date_range_week: function(dateRange){
              let start = null;
              if(dateRange.length > 7) {
                  start = new Date(dateRange[7]);
              }
              else{
                  start = new Date(dateRange[0]);
              }
              let day = start.getDay();
              this.selectedStartDate = [];
              start.setHours(-24 * (day - 1));
              this.selectedStartDate.push(start.toISOString().substr(0, 10));
              for (let i = 1; i < 7; i++) {
                  start.setHours(24);
                  this.selectedStartDate.push(start.toISOString().substr(0, 10));
              }
          },
          update_date_range_month: function(dateRange){
              let start = new Date(dateRange[dateRange.length - 1]);
              let month = start.getMonth();
              let year = start.getFullYear();
              this.selectedStartDate = [];
              start = new Date(year,month,1);
              let end = new Date(year,month+1,0);
              console.log(start);
              this.selectedStartDate.push(start.toISOString().substr(0, 10));
              while(start < end) {
                  start.setHours(24);
                  this.selectedStartDate.push(start.toISOString().substr(0, 10));
              }
          }
    }
  }
</script>

<template>
  <v-container>
    <v-row>
      <v-col sm="12" style="height: calc(0.5*(100vh - 64px));">
        <RunsSessionsGraph :download="download_mode" v-on:downloadElement="downloadElement"></RunsSessionsGraph>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <Sessions :download="download_mode" v-on:downloadElement="downloadElement"></Sessions>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" style="height: calc(0.6*(100vh - 64px));">
        <UserTotals :download="download_mode" v-on:downloadElement="downloadElement"></UserTotals>
      </v-col>
    </v-row>
    <!--<v-row>
      <v-col sm="12" style="height: calc((100vh - 64px));">
        <Permissions></Permissions>
      </v-col>
    </v-row>-->
    <v-overlay :value="confirm_download">
        <v-card class="DownloadCard">
          <v-card-title class="export_title">Confirm Download</v-card-title>
          <v-card-subtitle class="export_subtitle">Download Contents</v-card-subtitle>
          <v-row class="mx-6 my-0" v-for="content in Object.keys(downloaded_elements)" :key="content.id">
            <v-checkbox class="export_selector" v-model="confirm_download_content" :value="content" :label="downloaded_elements[content]['settings']['type'] + ' ' + content" ></v-checkbox>
          </v-row>
          <!--<v-card-text>{{this.confirm_download_text['sess']}} User Totals</v-card-text>-->
          <!--<v-card-text>{{this.confirm_download_text[1]}}'s Sessions</v-card-text>-->
          <v-card-subtitle class="pa-3 pt-0 export_subtitle">Download Format</v-card-subtitle>
          <v-radio-group v-model="selected_download_type" class="mx-6 mt-0 mb-6">
            <v-radio class="export_selector" v-for="type in download_types" :key="type.id" :label="type" :value="type"></v-radio>
          </v-radio-group>
          <v-row class="ma-0" style="width: 100%; position: absolute; bottom: 0; height: 7vh;" justify="space-around" align="center">
            <v-col cols="auto" class="ma-0 pa-0">
              <v-btn class="export_action" @click="downloadConfirmation(false)">X</v-btn>
            </v-col>
            <v-col cols="auto" class="ma-0 pa-0">
              <v-btn class="export_action" @click="downloadConfirmation(true)">Confirm</v-btn>
            </v-col>
          </v-row>
        </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
  import RunsSessionsGraph from '../components/RunsSessionsGraph'
  import Sessions from '../components/Sessions'
  import UserTotals from '../components/UserTotals'
  import PDFBuilder from '../javascript/pdfBuilder'
  // import Permissions from '../components/Permissions'
  export default {
    name: 'Home',
    props: [
        'Download'
    ],
    components: {
      RunsSessionsGraph,
      Sessions,
      UserTotals
    },
    data(){
        return {
            download_mode: false,
            downloaded_elements: {},
            confirm_download: false,
            confirm_download_content: [],
            download_types: [],
            selected_download_type: ''
        }
    },
    mounted(){
      import(`@/css/${this.$user.get_val()}/generalStyles.css`);
      import(`@/css/${this.$user.get_val()}/statisticsTable.css`);
      import(`@/css/${this.$user.get_val()}/graphs.css`);
      import(`@/css/${this.$user.get_val()}/export.css`);
      if(this.$user.get_val() == 'docma'){
          this.$vuetify.theme.themes.light.primary = '#EE8500';
          this.$vuetify.theme.themes.light.secondary = '#f3a815';
          this.$vuetify.theme.themes.light.third = '#000000';
          this.download_types = ['Pattison','Docma'];
          this.selected_download_type = 'Docma';
      }
      else{
          this.$vuetify.theme.themes.light.primary = '#00285e';
          this.$vuetify.theme.themes.light.secondary = '#0079C1';
          this.$vuetify.theme.themes.light.third = '#00285e';
          this.download_types = ['Pattison'];
          this.selected_download_type = 'Pattison'
      }
    },
    watch: {
        Download: {
            immediate: true,
            handler(){
                this.download_mode = this.Download;
            }
        }
    }  ,
    methods: {
        downloadElement: function (element){
            Object.assign(this.downloaded_elements,element);
            this.compileDownloadedElements();
        },
        compileDownloadedElements: function(){
            let elements = Object.keys(this.downloaded_elements);
            if(elements.includes('totals') && elements.includes('sessions') && elements.includes('overview')){
                this.confirm_download = true;
                this.confirm_download_content = Object.keys(this.downloaded_elements);
            }
        },
        downloadConfirmation: function(confirmed){
            if(confirmed) {
                let elements = this.confirm_download_content;
                let buld = new PDFBuilder();
                for(let i = 0; i < elements.length; i++){
                    buld.add_pdf_element(elements[i],this.downloaded_elements[elements[i]]);
                }
                buld.set_format(this.selected_download_type);
                buld.build();
                buld.save();
                this.downloaded_elements = {};
                this.confirm_download = false;
                this.$emit('downloadComplete')
            }
            else{
                this.downloaded_elements = {};
                this.confirm_download = false;
                this.$emit('downloadComplete')
            }
        }
    }
  }
</script>

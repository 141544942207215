<template>
    <v-card class="StatisticsCard">
        <v-card-title>
            <v-row class="ma-0 pa-0" style="height: calc(0.1*0.6*(100vh - 64px)); width: 100%;">
                <v-col class="ma-0 pa-0 statsTableTitle">
                    User Totals
                </v-col>
                <v-chip class="ma-2 statsTableChip chip-1" :outlined="viewing_totals != 'Today'" :disabled="loading_new_data" @click="viewing_totals='Today'">
                    <v-icon class="text-left mr-1">mdi-calendar</v-icon>
                    Today
                </v-chip>
                <v-chip class="ma-2 statsTableChip chip-2" :outlined="viewing_totals != 'This Week'" :disabled="loading_new_data" @click="viewing_totals='This Week'">
                    <v-icon class="text-left mr-1">mdi-calendar-range</v-icon>
                    This Week
                </v-chip>
                <v-chip class="ma-2 statsTableChip chip-3" :outlined="viewing_totals != 'This Month'" :disabled="loading_new_data" @click="viewing_totals='This Month'">
                    <v-icon class="text-left mr-1">mdi-calendar-month</v-icon>
                    This Month
                </v-chip>
                <v-chip class="ma-2 statsTableChip chip-4" :outlined="viewing_totals != 'All Time'" :disabled="loading_new_data" @click="viewing_totals='All Time'">
                    <v-icon class="text-left mr-1">mdi-calendar-blank-multiple</v-icon>
                    All Time
                </v-chip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="load_new_data()">mdi-refresh</v-icon>
                    </template>
                    <span>Last Refresh: </span>
                </v-tooltip>
            </v-row>
        </v-card-title>
        <v-row style="width: 100%; height: calc((0.9*0.6*(100vh - 64px)) - 61px);" align="center" justify="center" v-if="loading_new_data">
            <v-col cols="auto">
                <v-progress-circular indeterminate color="secondary" size="75"></v-progress-circular>
            </v-col>
        </v-row>
        <v-row style="width: 100%; height: calc((0.9*0.6*(100vh - 64px)) - 61px);" align="center" justify="center" v-if="users.length == 0 && !loading_new_data">
            <v-col cols="auto">
                <span class="statsTableHeader">No Available Totals</span>
            </v-col>
        </v-row>
        <v-data-table :headers="users_header" :sort-by.sync="sortBy" :items="users" :items-per-page="5" fixed-header height="calc((0.9*0.6*(100vh - 64px)) - 120px)" v-if="users.length > 0 && !loading_new_data">
            <template v-slot:body="{items}">
                <tbody>
                    <tr v-for="row in items" :key="row.id">
                        <td class="text-left statsTableData">{{row['user']}}</td>
                        <td class="text-left statsTableData">{{row['num_runs']}}</td>
                        <td class="text-left statsTableData">{{row['num_pdfs'] + row['num_pptxs']}}</td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import Stats from '../javascript/statistics_caller'
    export default {
        name: "UserTotals",
        props: [
          'download'
        ],
        data(){
            return{
                users_header: [
                    {text: 'User name', align: 'start', value: 'user'},
                    {text: 'Runs', align: 'start', value: 'runs'},
                    {text: 'Exports', align: 'start', value: 'pdfs'},
                ],
                users: [],
                loading_new_data: true,
                viewing_totals: 'All Time',
                sortBy: 'user'
            }
        },
        watch:{
            viewing_totals: {
                immediate: true,
                handler: function(){
                    this.load_new_data(this.viewing_totals);
                }
            },
            download: {
                immediate: true,
                handler: function () {
                    if(this.download == true) {
                        let element = {'settings': {'type': this.viewing_totals}, 'data': this.users};
                        this.$emit('downloadElement', {'totals': element})
                    }
                }
            },
            sortBy: {
                immediate: true,
                handler: function () {
                    if(this.sortBy == undefined){
                        console.log('here');
                        this.sortBy = "user";
                    }
                }
            }
        },
        mounted(){
            this.load_new_data();
        },
        methods: {
            load_new_data: async function(){
                this.loading_new_data = true;
                let token = this.$token.get_val();
                let users = [];
                if(this.viewing_totals == 'All Time') {
                    users = await Stats.user_totals(token).then((resp) => {
                        return resp
                    }).catch((err) => {
                        console.error(err)
                        return []
                    });
                    this.users = users;
                }
                else if(this.viewing_totals == 'Today'){
                    users = await Stats.todays_user_totals(token).then((resp) => {
                        return resp
                    }).catch((err) => {
                        console.error(err)
                        return []
                    });
                }
                else if(this.viewing_totals == 'This Week'){
                    let today = new Date();
                    let week_str = today.getFullYear() + '-' + (today.getMonth() + 1).toString() + '-' + today.getDate();
                    users = await Stats.weeks_user_totals(token,week_str).then((resp) => {
                        return resp
                    }).catch((err) => {
                        console.error(err)
                        return []
                    });
                }
                else if(this.viewing_totals == 'This Month'){
                    let today = new Date();
                    let month_str = today.getFullYear() + '-' + (today.getMonth() + 1).toString() + '-' + today.getDate();
                    users = await Stats.months_user_totals(token,month_str).then((resp) => {
                        return resp
                    }).catch((err) => {
                        console.error(err)
                        return []
                    });
                }
                else{
                    this.users = users;
                }

                if(this.viewing_totals == 'Today' || this.viewing_totals == 'This Month' || this.viewing_totals == 'This Week'){
                    if(typeof users == 'string'){
                        users = JSON.parse(users);
                    }
                    let u_keys = Object.keys(users);
                    let updated = [];
                    console.log(u_keys);
                    for(let u_key of u_keys){
                        updated.push(users[u_key]);
                    }
                    this.users = updated;
                }
                this.loading_new_data = false;
            }
        }
    }
</script>

<style scoped>

</style>
import axios from 'axios'

let api_base = "https://statistics.api.docmasweetspot.ca/statistics";

function get_week_at_a_glance_runs(token){
    let api_call = api_base + '/weekglance/general_runs';
    return get_call(api_call,token)
}
function get_week_at_a_glance_sessions(token){
    let api_call = api_base + '/weekglance/general_sessions';
    return get_call(api_call,token)
}
function get_week_at_a_glance_average_runs(token){
    let api_call = api_base + '/weekglance/average/runs';
    return get_call(api_call,token)
}
function get_week_at_a_glance_average_sessions(token){
    let api_call = api_base + '/weekglance/average/sessions';
    return get_call(api_call,token)
}
function get_month_at_a_glance_runs(token){
    let api_call = api_base + '/monthglance/general_runs';
    return get_call(api_call,token)
}
function get_month_at_a_glance_sessions(token){
    let api_call = api_base + '/monthglance/general_sessions';
    return get_call(api_call,token)
}
function get_month_at_a_glance_average_runs(token){
    let api_call = api_base + '/monthglance/average/runs';
    return get_call(api_call,token)
}
function get_month_at_a_glance_average_sessions(token){
    let api_call = api_base + '/monthglance/average/sessions';
    return get_call(api_call,token)
}
function get_past_week_at_a_glance_runs(token,date){
    let api_call = api_base + '/weekglance/past_runs';
    return post_call(api_call,date,token);
}
function get_past_week_at_a_glance_sessions(token,date){
    let api_call = api_base + '/weekglance/past_sessions';
    return post_call(api_call,date,token);
}
function get_past_week_at_a_glance_average_runs(token,date){
    let api_call = api_base + '/weekglance/average/past_runs';
    return post_call(api_call,date,token);
}
function get_past_week_at_a_glance_average_sessions(token,date){
    let api_call = api_base + '/weekglance/average/past_sessions';
    return post_call(api_call,date,token);
}
function get_past_month_at_a_glance_runs(token,date){
    let api_call = api_base + '/monthglance/past_runs';
    return post_call(api_call,date,token);
}
function get_past_month_at_a_glance_sessions(token,date){
    let api_call = api_base + '/monthglance/past_sessions';
    return post_call(api_call,date,token)
}
function get_past_month_at_a_glance_average_runs(token,date){
    let api_call = api_base + '/monthglance/average/past_runs';
    return post_call(api_call,date,token);
}
function get_past_month_at_a_glance_average_sessions(token,date){
    let api_call = api_base + '/monthglance/average/past_sessions';
    return post_call(api_call,date,token);
}
function get_past_min_max(token){
    let api_call = api_base + '/weekglance/past/min_max';
    return get_call(api_call,token)
}
function todays_sessions(token){
    let api_call = api_base + '/sessions/today';
    return get_call(api_call,token)
}
function week_sessions(token){
    let api_call = api_base + '/sessions/week';
    return get_call(api_call,token)
}
function month_sessions(token){
    let api_call = api_base + '/sessions/month';
    return get_call(api_call,token)
}
function user_totals(token){
    let api_call = api_base + '/users';
    return get_call(api_call,token)
}
function todays_user_totals(token){
    let api_call = api_base + '/dayglance/userTotals';
    return get_call(api_call,token)
}
function weeks_user_totals(token,date){
    let api_call = api_base + '/weekglance/userTotals?token=' + token;
    return post_call(api_call,date,token);
}
function months_user_totals(token,date){
    let api_call = api_base + '/monthglance/userTotals?token=' + token;
    return post_call(api_call,date,token)
}
function get_call(call,token){
    return new Promise(function(resolve,reject){
        axios.get(call, {headers: {'x-access-tokens': token}}).then((res)=>{
            if(res.status == 200){
                resolve(res.data);
            }
            else{
                reject();
            }
        })
    })
}
function post_call(call,date,token){
    return new Promise(function(resolve,reject){
        axios.post(call, {'date':date}, {headers: {'x-access-tokens': token}}).then((res)=>{
            if(res.status == 200){
                resolve(res.data);
            }
            else{
                reject();
            }
        })
    })
}

export default {get_week_at_a_glance_runs,get_week_at_a_glance_sessions,get_week_at_a_glance_average_runs,get_week_at_a_glance_average_sessions,get_month_at_a_glance_runs,get_month_at_a_glance_sessions,get_month_at_a_glance_average_runs,get_month_at_a_glance_average_sessions,get_past_week_at_a_glance_runs,get_past_week_at_a_glance_sessions,get_past_week_at_a_glance_average_runs,get_past_week_at_a_glance_average_sessions,get_past_month_at_a_glance_runs,get_past_month_at_a_glance_sessions,get_past_month_at_a_glance_average_runs,get_past_month_at_a_glance_average_sessions,get_past_min_max,todays_sessions,week_sessions,month_sessions,user_totals,todays_user_totals,weeks_user_totals,months_user_totals}
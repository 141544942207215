import jsPDF from 'jspdf'
import 'jspdf-autotable'
import pattisonLogo from '../assets/PattisonLogo.png'
import docmaLogo from '../assets/DocmaLogoPNG.png'
import futuraL from '../fonts/futura-t-light.ttf'
import futuraB from '../fonts/futura-t-bold.ttf'
import poppinsL from '../fonts/Poppins-Light.ttf'
import poppinsB from '../fonts/Poppins-Bold.ttf'
class PDFBuilder{
    constructor(){
        this.pdf = new jsPDF('w', 'pt', 'a4');
        this.pdf_width = this.pdf.internal.pageSize.getWidth();
        this.pdf_height = this.pdf.internal.pageSize.getHeight();
        this.pdf_x_break = this.pdf_width / 20;
        this.pdf_y_break = this.pdf_height / 20;
        this.pdf_cur_y = 0;
        this.format = 'Docma';
        this.table_colors = '#f3a815';
        this.font_name = 'Arial';
        this.logo = docmaLogo;
        this.pdf_prename = 'Docma';
        this.pdf_name = 'SweetSpotUsage.pdf';
        this.pdf_elements = {
            'logo': this.logo
        };
    }
    add_pdf_element(elementName, element){
        this.pdf_elements[elementName] = element;
    }
    set_format(format){
        this.format = format;
        if(this.format == 'Docma'){
            this.table_colors = '#f3a815';
            this.logo = docmaLogo;
            this.pdf_prename = 'Docma';
            this.font_name = 'Poppins';
        }
        else if(this.format == 'Pattison'){
            this.table_colors = '#00285e';
            this.logo = pattisonLogo;
            this.pdf_prename = 'Pattison';
            this.font_name = 'Futura';
        }
    }
    build(){
        this.addFonts();
        this.add_main_logo();
        let elements = Object.keys(this.pdf_elements);
        if(elements.includes('overview')){
            this.add_chart();
        }
        if(elements.includes('sessions')) {
            this.build_session_table();
        }
        if(elements.includes('totals')){
            this.build_user_table();
        }
    }
    addFonts(){
        if(this.format == 'Docma'){
            this.pdf.addFont(poppinsL, "Poppins", "normal");
            this.pdf.addFont(poppinsB, "Poppins", "bold");
        }
        else if(this.format == 'Pattison'){
            this.pdf.addFont(futuraL, "Futura", "normal");
            this.pdf.addFont(futuraB, "Futura", "bold");
        }
    }
    add_main_logo(){
        let imgWidth = this.pdf_x_break * 3;
        let imgHeight = imgWidth / 4;
        let imgxLoc = this.pdf_x_break * 8.5;
        let imgyLoc = this.pdf_y_break / 2;
        this.pdf.addImage(this.logo,imgxLoc,imgyLoc,imgWidth,imgHeight);
        this.updateCurY(imgHeight + (imgyLoc * 2));
    }
    add_chart(){
        let imgWidth = this.pdf_x_break * 18;
        let imgHeight = this.pdf_y_break * 5;
        this.pdf.addImage(this.pdf_elements['overview']['data'], this.pdf_x_break, this.pdf_cur_y, imgWidth, imgHeight);
        this.updateCurY(imgHeight + 10);
    }
    build_session_table(){
        let data_header = [['User','Runs','Exports','Session Start','Session Time']];
        let data = [];
        let raw_data = this.pdf_elements['sessions']['data'];
        let tmp = [];
        for(let i = 0; i < raw_data.length; i++){
            tmp = [];
            tmp.push(raw_data[i]['user']);
            tmp.push(raw_data[i]['runs']);
            tmp.push(raw_data[i]['pdfs'] + raw_data[i]['pptxs']);
            tmp.push(this.formatDate(raw_data[i]['session_start']));
            tmp.push(this.timeDiffCalc(raw_data[i]['session_end'],raw_data[i]['session_start']));
            data.push(tmp);
        }
        this.pdf.setFont(this.font_name, "bold");
        this.pdf.setFontSize(14);
        this.updateCurY(14);
        this.pdf.text(`${this.pdf_elements['sessions']['settings']['type']}'s User Sessions`, 40, this.pdf_cur_y);
        this.updateCurY(10);
        this.pdf.autoTable({startY: this.pdf_cur_y, head: data_header, body: data, headStyles: {fillColor: this.table_colors, font: this.font_name, fontStyle: "bold"}, styles: {font: this.font_name, fontStyle: "normal"}});
        this.updateCurY((this.pdf.previousAutoTable.finalY - this.pdf_cur_y) + 10);
        this.updateCurY(14);
        this.pdf.text(`${this.pdf_elements['sessions']['settings']['type']}'s User Session Totals`, 40, this.pdf_cur_y);
        this.updateCurY(24);
        this.pdf.setFont(this.font_name, "normal");
        this.pdf.setFontSize(12);
        this.pdf.text(`Total Users: ${this.pdf_elements['sessions']['totals']['users']}`, 40, this.pdf_cur_y);
        this.updateCurY(16);
        this.pdf.text(`Total Runs: ${this.pdf_elements['sessions']['totals']['runs']}`, 40, this.pdf_cur_y);
        this.updateCurY(16);
        this.pdf.text(`Total Exports: ${this.pdf_elements['sessions']['totals']['exports']}`, 40, this.pdf_cur_y);
        this.updateCurY(16);
        this.pdf.text(`Total Time: ${this.pdf_elements['sessions']['totals']['time']}`, 40, this.pdf_cur_y);
        this.updateCurY(20);
    }
    build_user_table(){
        let data_header = [['User','Runs','Exports']];
        let data = [];
        let raw_data = this.pdf_elements['totals']['data'];
        let tmp = [];
        for(let i = 0; i < raw_data.length; i++){
            tmp = [];
            tmp.push(raw_data[i]['user']);
            tmp.push(raw_data[i]['num_runs']);
            tmp.push(raw_data[i]['num_pdfs'] + raw_data[i]['num_pptxs']);
            data.push(tmp);
        }
        this.pdf.setFont(this.font_name, "bold");
        this.pdf.setFontSize(14);
        this.updateCurY(14);
        this.pdf.text("User Totals", 40, this.pdf_cur_y);
        this.updateCurY(10);
        this.pdf.autoTable({startY: this.pdf_cur_y, head: data_header, body: data, headStyles: {fillColor: this.table_colors, font: this.font_name, fontStyle: "bold"}, styles: {font: this.font_name, fontStyle: "normal"}});
        this.updateCurY(this.pdf.previousAutoTable.finalY + 10);
    }
    save(){
        this.pdf.save(this.pdf_prename + this.pdf_name);
    }
    updateCurY(increaseBy){
        if((this.pdf_cur_y + increaseBy) > ((this.pdf.getNumberOfPages() * this.pdf_height) - (this.pdf_y_break * 2))){
            this.pdf.addPage();
            this.pdf.setPage(this.pdf.getNumberOfPages());
            this.pdf_cur_y = (this.pdf_y_break);
        }
        else{
            this.pdf_cur_y += increaseBy;
        }
    }
    timeDiffCalc(dateFuture, dateNow) {
        dateFuture = new Date(dateFuture);
        dateNow = new Date(dateNow);

        let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

        // calculate days
        const days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;

        // calculate hours
        const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;

        // calculate minutes
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;

        let difference = '';
        if (days > 0) {
            difference += (days === 1) ? `${days} day, ` : `${days} days, `;
        }
        if (hours > 0) {
            difference += (hours === 0 || hours === 1) ? `${hours} hour, ` : `${hours} hours, `;
        }

        difference += (minutes === 0 || hours === 1) ? `${minutes} minutes` : `${minutes} minutes`;

        return difference;
    }
    formatDate(date){
        date = new Date(date);
        date.setTime(date.getTime() + 3600 * 1000);
        let str = date.toUTCString().substr(5,17);
        return str;
    }
}
export default PDFBuilder
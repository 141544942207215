var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"StatisticsCard"},[(!_vm.loading_new_data)?_c('v-row',{staticClass:"ma-0 pa-0",staticStyle:{"width":"100%","height":"100%"}},[_c('v-col',{staticClass:"ma-0 pa-0",staticStyle:{"width":"90%","height":"100%"},attrs:{"cols":"auto"}},[_c('GChart',{ref:"statsChart",staticStyle:{"width":"90%","height":"100%","position":"absolute","z-index":"2"},attrs:{"type":"LineChart","data":_vm.chartData,"options":_vm.chartOptions}})],1),_c('v-col',{staticClass:"ma-0 pa-0",staticStyle:{"width":"10%","height":"100%"},attrs:{"cols":"auto"}},[_c('v-row',{staticClass:"ma-0 pa-0",staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.get_past_week_data()}}},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}],null,false,3266987251)},[_c('p',[_vm._v("Last Refresh: "+_vm._s(_vm.last_refresh))]),_c('p',[_vm._v("Last System Refresh: "+_vm._s(_vm.last_sys_refresh))])])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"offset-x":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"third"}},'v-icon',attrs,false),on),[_vm._v("mdi-calendar-range")])]}}],null,false,839226026),model:{value:(_vm.date_selector),callback:function ($$v) {_vm.date_selector=$$v},expression:"date_selector"}},[_c('v-card',{staticClass:"pa-3"},[_c('v-menu',{ref:"startDatePicker",attrs:{"close-on-content-click":false,"return-value":_vm.selectedStartDate,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.selectedStartDate=$event},"update:return-value":function($event){_vm.selectedStartDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"selectors",attrs:{"label":_vm.month_view ? 'Month' : 'Week',"prepend-icon":"mdi-calendar-search","color":"primary","readonly":""},model:{value:(_vm.selectedStartDate[0]),callback:function ($$v) {_vm.$set(_vm.selectedStartDate, 0, $$v)},expression:"selectedStartDate[0]"}},'v-text-field',attrs,false),on))]}}],null,false,1449694440),model:{value:(_vm.startDatePicker),callback:function ($$v) {_vm.startDatePicker=$$v},expression:"startDatePicker"}},[_c('v-date-picker',{staticClass:"selectorCalendar",attrs:{"no-title":"","scrollable":"","min":_vm.minStartDate,"max":_vm.maxEndDate,"multiple":""},on:{"input":function($event){return _vm.update_date_range($event)}},model:{value:(_vm.selectedStartDate),callback:function ($$v) {_vm.selectedStartDate=$$v},expression:"selectedStartDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.startDatePicker = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.startDatePicker.save(_vm.selectedStartDate); _vm.startDatePicker = false; _vm.date_selector = false; _vm.get_past_week_data()}}},[_vm._v("OK")])],1)],1),_c('v-switch',{attrs:{"label":"View By Month"},on:{"change":function($event){_vm.update_date_range(_vm.selectedStartDate); _vm.get_past_week_data()}},model:{value:(_vm.month_view),callback:function ($$v) {_vm.month_view=$$v},expression:"month_view"}},[_vm._v("Month View")])],1)],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"offset-x":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"third"}},'v-icon',attrs,false),on),[_vm._v("mdi-account-group-outline")])]}}],null,false,3027968404),model:{value:(_vm.group_selector),callback:function ($$v) {_vm.group_selector=$$v},expression:"group_selector"}},[_c('v-card',{staticClass:"pa-3"},[_c('v-select',{staticClass:"selectors",attrs:{"multiple":"","items":_vm.groups},model:{value:(_vm.selected_groups),callback:function ($$v) {_vm.selected_groups=$$v},expression:"selected_groups"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"prepend-icon":"mdi-play-outline","color":"third"},model:{value:(_vm.view_runs),callback:function ($$v) {_vm.view_runs=$$v},expression:"view_runs"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"prepend-icon":"mdi-account-multiple","color":"third"},model:{value:(_vm.view_sessions),callback:function ($$v) {_vm.view_sessions=$$v},expression:"view_sessions"}})],1)],1)],1)],1):_vm._e(),(_vm.loading_new_data)?_c('v-row',{staticClass:"ma-0 pa-0",staticStyle:{"width":"100%","height":"100%"},attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary","size":"75"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app>
    <v-app-bar app color="third" dark>
      <v-row style="height: 100%; width: 100%;" class="ma-0 pa-0">
        <v-col cols="3"></v-col>
        <v-col cols="6" class="pa-1" style="max-height: 100%;">
          <v-img :src="$user.get_val() == 'docma' ? require('./assets/DocmaLogo.svg') : require('./assets/PattisonLogo.png')" contain max-height="100%"></v-img>
        </v-col>
        <v-col cols="3" class="text-right">
          <v-icon class="mr-2" v-if="this.$token.get_val()" @click="download=true">mdi-export-variant</v-icon>
          <v-icon class="mx-2" @click="logout" v-if="this.$token.get_val()">mdi-exit-run</v-icon>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main>
      <router-view :Download="download" v-on:downloadComplete="download = false"/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
      download: false
  }),
  methods: {
      logout: function(){
          this.$token.set_val(false);
          this.$user.set_val('docma');
          this.$vuetify.theme.themes.light.primary = '#EE8500';
          this.$vuetify.theme.themes.light.secondary = '#f3a815';
          this.$vuetify.theme.themes.light.third = '#000000';
          this.$router.replace('/');
      }
  }
};
</script>
<style>
  /*@import 'css/pattisonoutdoor/generalStyles.css';*/
  /*@import 'css/pattisonoutdoor/statisticsTable.css';*/
  /*@import 'css/pattisonoutdoor/graphs.css';*/
  @font-face {
    font-family: 'FuturaTLight';
    src: local('FuturaTLight'),
    url('./fonts/futura-t-light.ttf') format('truetype');
  }
  @font-face {
    font-family: 'FuturaTBold';
    src: local('FuturaTBold'),
    url('./fonts/futura-t-bold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'FuturaTMedium';
    src: local('FuturaTMedium'),
    url('./fonts/futura-t-medium.ttf') format('truetype');
  }
</style>

<template>
    <v-container color="black">
        <v-layout text-center>
            <v-flex lg6 class="mx-auto">
                <v-card outlined class="pa-4 my-10">
                    <v-form>
                        <v-text-field outlined type="text" placeholder="Email" v-model="email" class="ma-4 loginFormTextStyle" color="primary" @keyup.enter="login"></v-text-field>
                        <v-text-field outlined type="password" placeholder="Password" v-model="password" class="ma-4 loginFormTextStyle" color="primary" @keyup.enter="login"></v-text-field>
                        <v-btn outlined x-large class="ma-4 loginFormTextStyle" color="primary" @click="login">Log In</v-btn>
                    </v-form>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import request from 'request';
    export default {
        name: "Login",
        data() {
            return {
                email: '',
                password: ''
            }
        },
        mounted(){
          import(`../css/${this.$user.get_val()}/graphs.css`)  ;
        },
        methods: {
            login: async function(){
                await this.getApiToken(this.email,this.password).then((val) => {
                    let email_ext = this.email.split('@')[1];
                    email_ext = email_ext.split('.')[0];
                    console.log(email_ext)
                    this.$user.set_val(email_ext);
                    this.$token.set_val(val);
                    this.$router.replace('home');
                }).catch((error) => {
                    alert('Oops... Could Not Verify');
                    console.error(error);
                })
            },
            getApiToken: function(email,password){
                const url = 'https://www.api.docmasweetspot.ca:443/authorization/statistics/login';
                var authCredentials = {
                    'username': email,
                    'password': password
                };
                return new Promise((resolve, reject) => {
                    request.get(url,{'auth': authCredentials},function(error,res,body){
                        if(error || res.statusCode != 200){
                            reject(error)
                        }
                        else {
                            console.log(body);
                            var token = JSON.parse(body);
                            token = token["token"];
                            resolve(token)
                        }
                    });
                })
            }
        }
    }
</script>

<style scoped>
</style>
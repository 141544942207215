import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import gcharts from './plugins/gcharts'

Vue.config.productionTip = false;
Vue.prototype.$token = {
    val: false,
    set_val: function(token){
      this.val = token;
    },
    get_val: function(){
      return this.val;
    }};
Vue.prototype.$user = {
    val: 'docma',
    set_val: function(value){
        this.val = value;
    },
    get_val: function(){
        return this.val;
    }};

new Vue({
  router,
  vuetify,
  gcharts,
  render: h => h(App),
}).$mount('#app')
